html {
  font-size: 65%;
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 1.3rem;
}

::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  background: #797979;
  transition: all 0.5s ease-in-out;
}

::-webkit-scrollbar-thumb:hover {
  background: #222224;
}

::-webkit-scrollbar-track {
  background: #f9f9f9;
}

.container {
  max-width: 150rem;
  padding: 4rem 1rem;
  margin: 0 auto;
}

.swiper_container {
  height: 52rem;
  padding: 2rem 0;
  position: relative;
}

.swiper-slide {
  width: 52rem !important;
  height: 42rem !important;
  position: relative;
}

.swiper-slide img {
  width: 52rem !important;
  height: 42rem !important;
  border-radius: 2rem;
  object-fit: cover;
}
.slider-arrow {
  color: var(--hl-color) !important;
  background-color: var(--grey);
  border-radius: 10%;
}

.swiper-pagination .swiper-pagination-bullet {
  filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
  background-color: var(--text-color);
}

.swiper-pagination .swiper-pagination-bullet-active {
  background: var(--hl-color);
}

/* media queries */

@media (max-width: 890px) {
  .swiper_container {
    height: 36rem;
  }

  .swiper-slide {
    width: 30rem !important;
    height: 36rem !important;
  }
  .swiper-slide img {
    width: 34rem !important;
    height: 36rem !important;
  }

  .swiper-pagination {
    display: none;
  }
  .swiper-slide {
    height: 100%;
    display: flex !important;
    justify-content: center;
    align-items: center;
    margin: 0;
  }
}
